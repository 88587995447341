import { Injectable } from '@angular/core';
import { firstValueFrom, map, switchMap } from 'rxjs';
import {
  CreateOnePredictorGQL,
  CreatePredictorDto,
  CreatedPredictorGQL,
  DeleteOnePredictorGQL,
  DeletedOnePredictorGQL,
  PredictorFilter,
  PredictorGQL,
  PredictorSortFields,
  PredictorsGQL,
  SortDirection,
  UpdateOnePredictorGQL,
  UpdatePredictorDto,
  UpdatedOnePredictorGQL
} from 'src/app/graphql/frontend-data-graphql';

@Injectable({ providedIn: 'root' })
export class PredictorService {
  constructor(
    private predictorsGQL: PredictorsGQL,
    private predictorGQL: PredictorGQL,
    private createGQL: CreateOnePredictorGQL,
    private updateGQL: UpdateOnePredictorGQL,
    private deleteGQL: DeleteOnePredictorGQL,
    private createdGQL: CreatedPredictorGQL,
    private updatedGQL: UpdatedOnePredictorGQL,
    private deletedGQL: DeletedOnePredictorGQL
  ) {}

  async findByOrganization(organizationId: string) {
    const { data, errors } = await firstValueFrom(this.findMany());
    return data.predictors.edges.map(e => e.node).filter(p => p.organizationIds.length === 0 || p.organizationIds.includes(organizationId));
  }

  findMany(filter: PredictorFilter = {}) {
    return this.predictorsGQL.fetch({
      filter,
      paging: { first: 50 },
      sorting: { field: PredictorSortFields.Updated, direction: SortDirection.Desc }
    });
  }

  async findOne(id: string) {
    const { data, errors } = await firstValueFrom(this.predictorGQL.fetch({ id }));
    return { data, errors };
  }

  create(input: CreatePredictorDto) {
    return this.createGQL.mutate({ input });
  }

  update(id: string, update: UpdatePredictorDto) {
    return this.updateGQL.mutate({ id, update });
  }

  delete(id: string) {
    return this.deleteGQL.mutate({ id });
  }

  observeCreated(filter: PredictorFilter) {
    return this.createdGQL.subscribe().pipe(map(({ data }) => data!.createdPredictor));
  }

  observeUpdated(filter: PredictorFilter) {
    return this.updatedGQL.subscribe().pipe(map(({ data }) => data!.updatedOnePredictor));
  }

  observeDeleted(filter: PredictorFilter) {
    return this.deletedGQL.subscribe().pipe(map(({ data }) => data!.deletedOnePredictor));
  }
}
